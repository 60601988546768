<script setup lang="ts">
import { SpSvg } from '~~/src/autogen/SpSvg'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'
import { useCalendarPicker } from '~~/src/hooks/calendar/picker'

const props = defineProps({
	date: { type: Date, default: undefined },
})
const { date } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change])
const target = ref(null)

const { toggle, toggleValue, text, editableDate, onModelUpdate, currentLocale } = useCalendarPicker(date, target, emit)
</script>

<template>
	<div ref="target">
		<div
			@click="toggle()"
			class="flex w-full cursor-pointer items-center justify-between rounded-md py-4 text-sm font-semibold"
		>
			<div class="flex gap-2">
				<span>
					{{ text }}
				</span>
				<CommonIcon :icon-name="SpSvg.BasicCarretDown" fill="fill-dark-blue-800" :icon-size="IconSizes.S" />
			</div>
		</div>
		<div v-if="toggleValue" class="absolute z-20 translate-y-2">
			<v-date-picker v-model="editableDate" v-on:update:modelValue="onModelUpdate" :locale="currentLocale" />
		</div>
	</div>
</template>
