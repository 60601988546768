<script setup lang="ts">
import { PropType } from 'vue'
import { TestIds } from '~/constants/TestIds'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TextSizes } from '~~/src/constants/textSizes'
import { useCalendarPicker } from '~~/src/hooks/calendar/picker'

const props = defineProps({
	date: { type: Date, default: undefined },
	hasError: { type: Boolean, default: false },
	errorMessage: { type: String as PropType<TranslationKey> },
})
const { date, hasError, errorMessage } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change])
const target = ref(null)

const errorClasses = computed(() => (hasError.value ? 'border-red-500' : 'border-main-blue-200'))

const { toggle, toggleValue, text, editableDate, onModelUpdate, currentLocale } = useCalendarPicker(date, target, emit)
</script>

<template>
	<div ref="target">
		<div
			@click="toggle"
			class="flex w-full cursor-pointer items-center justify-between rounded-md border bg-main-blue-50 px-4 py-2 text-sm"
			:class="errorClasses"
		>
			<div class="flex gap-2">
				<CommonIcon :icon-name="SpSvg.BasicCalendar" fill="fill-main-blue-600" :icon-size="IconSizes.S" />
				<span>
					{{ text }}
				</span>
			</div>
			<CommonIcon v-if="hasError" :icon-name="SpSvg.BasicErrorCircle" fill="fill-red-500" />
		</div>
		<div v-if="toggleValue" class="absolute z-20 translate-y-2">
			<v-date-picker
				v-model="editableDate"
				v-on:update:modelValue="onModelUpdate"
				:locale="currentLocale"
				:data-testid="TestIds.CalendarDatepicker"
			/>
		</div>
		<CommonText
			v-if="hasError && errorMessage != undefined"
			:text="errorMessage"
			class="whitespace-normal text-red-400"
			:text-size="TextSizes.BODY_REGULAR"
		/>
	</div>
</template>
