<script lang="ts" setup>
import { PropType } from 'vue'
import { CalendarPickerDesktop, CalendarPickerMobile } from '#components'
import { useMobileInfos } from '~/hooks/useMobileInfos'
import { TranslationKey } from '~/i18n/TranslationKeys';

const props = defineProps({
    date: { type: Date, default: undefined },
    hasError: { type: Boolean, default: false },
    errorMessage: { type: String as PropType<TranslationKey> },
})

const { isMobile } = useMobileInfos()
</script>

<template>
    <component :is="isMobile ? CalendarPickerMobile : CalendarPickerDesktop" v-bind="props" />
</template>
