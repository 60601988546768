import { onClickOutside } from '@vueuse/core'
import { Ref } from 'vue'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { EmitsEnum } from '~~/src/constants/emits'
import { useToggle } from '~~/src/hooks/useToggle'
import { utilDate } from '~~/src/utils/utilDate'

export const useCalendarPicker = (date: Ref<Date | undefined> | undefined, target: Ref, emit: any) => {
	const currentLocale = useLocale().currentLocale

	// format data for v-calendar
	const editableDate = ref(date?.value == undefined ? undefined : new Date(date.value))
	// @ts-ignore
	watch(date, () => {
		editableDate.value = date?.value == undefined ? undefined : new Date(date.value)
	})

	// handle values update
	const onModelUpdate = (val: any) => {
		emit(EmitsEnum.Change, val)
	}

	// handle the text
	const i18n = useI18n()
	const text = computed(() =>
		date?.value != undefined
			? utilDate.formatTextualDate(date.value, useLocale().currentLocale.value)
			: i18n.t(TranslationKeys.CHOSE_AN_OPTION)
	)

	// on click outside, close menu
	const { toggleValue, toggle, setToggle } = useToggle()
	onClickOutside(target, () => setToggle(false))

	return { toggle, toggleValue, text, editableDate, onModelUpdate, currentLocale }
}
